/* eslint-disable camelcase */
import { NUMBER_REGEX } from '../../../../../constants/RegexConstants';
import { saveApplicationStepDataToBackend, updateEditApplicationData } from '../../../redux/PublicApplicationAction';

export const publicApplicationDirectorPrincipalDetailsStepValidation = async (
  dispatch,
  data,
  applicationId,
  clientId,
) => {
  const errors = {};
  let validated = true;
  const stateOne = data?.state_one?.label === undefined ? data?.state_one : data?.state_one?.label;
  const stateSecond = data?.state_second?.label === undefined ? data?.state_second : data?.state_second?.label;

  // For title one
  if (!data?.title_one) {
    validated = false;
    errors.title_one = 'Please enter Title ';
  }
  // For name one
  if (!data?.name_one || data?.name_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.name_one = 'Please enter Name ';
  }
  if (data?.name_one?.toString()?.trim()?.length > 50) {
    validated = false;
    errors.name_one = 'Please enter valid Name ';
  }
  // For position one
  if (!data?.position_one || data?.position_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.position_one = 'Please enter Position ';
  }

  if (data?.position_one?.toString()?.trim()?.length > 30) {
    validated = false;
    errors.position_one = 'Please enter valid Position ';
  }
  // For date of birth one
  if (!data?.dateOfBirth_one || data?.dateOfBirth_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.dateOfBirth_one = 'Please enter valid Date of Birth ';
  }
  // For driver license one
  if (!data?.driverLicense_one || data?.driverLicense_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.driverLicense_one = 'Please enter Driver License ';
  }
  // For email one
  if (!data?.email_one || data?.email_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.email_one = 'Please enter Email ';
  }
  if (data?.email_one && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.email_one)) {
    validated = false;
    errors.email_one = 'Please enter valid Email ';
  }

  // For mobile one
  if (!data?.mobile_one || data?.mobile_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.mobile_one = 'Please enter Mobile Number ';
  }
  if (data?.mobile_one?.toString()?.trim()?.length >= 13) {
    validated = false;
    errors.mobile_one = 'Please enter valid Mobile Number ';
  }
  if (data?.mobile_one && !NUMBER_REGEX.test(data?.mobile_one?.toString()?.trim())) {
    validated = false;
    errors.mobile_one = 'Please enter valid Mobile Number ';
  }

  // For address line one
  if (!data?.addressLine1_one || data?.addressLine1_one?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.addressLine1_one = 'Please enter Address Line 1 ';
  }

  // Validation For Driving License according to country and its state

  if (data?.country_one?.label === 'Australia') {
    if (stateOne === 'New South Wales' && !/^\d{10}$/.test(data?.driverLicense_one)) {
      validated = false;
      errors.driverLicense_one = 'Please enter a valid 10 digit numeric Driver Licence for New South Wales';
    }
    if (stateOne === 'Northern Territory' && !/^\d{6,8}$/.test(data?.driverLicense_one)) {
      validated = false;
      errors.driverLicense_one = 'Please enter a valid 6-8 digit numeric Driver Licence for Northern Territory';
    }
    if (stateOne === 'Queensland' && !/^\d{10}$/.test(data?.driverLicense_one)) {
      validated = false;
      errors.driverLicense_one = 'Please enter a valid 10 digit numeric Driver Licence for Queensland';
    }
    if (stateOne === 'South Australia' && !/^\d{9}$/.test(data?.driverLicense_one)) {
      validated = false;
      errors.driverLicense_one = 'Please enter a valid 9 digit numeric Driver Licence for South Australia';
    }
    if (stateOne === 'Victoria' && !/^\d{9}$/.test(data?.driverLicense_one)) {
      validated = false;
      errors.driverLicense_one = 'Please enter a valid 9 digit numeric Driver Licence for Victoria';
    }
  }

  if (data?.country_second?.label === 'Australia') {
    if (stateSecond === 'New South Wales' && !/^\d{10}$/.test(data?.driverLicense_second)) {
      validated = false;
      errors.driverLicense_second = 'Please enter a valid 10 digit numeric Driver Licence for New South Wales';
    }
    if (stateSecond === 'Northern Territory' && !/^\d{6,8}$/.test(data?.driverLicense_second)) {
      validated = false;
      errors.driverLicense_second = 'Please enter a valid 6-8 digit numeric Driver Licence for Northern Territory';
    }
    if (stateSecond === 'Queensland' && !/^\d{10}$/.test(data?.driverLicense_second)) {
      validated = false;
      errors.driverLicense_second = 'Please enter a valid 10 digit numeric Driver Licence for Queensland';
    }
    if (stateSecond === 'South Australia' && !/^\d{9}$/.test(data?.driverLicense_second)) {
      validated = false;
      errors.driverLicense_second = 'Please enter a valid 10 digit numeric Driver Licence for South Australia';
    }
    if (stateSecond === 'Victoria' && !/^\d{9}$/.test(data?.driverLicense_second)) {
      validated = false;
      errors.driverLicense_second = 'Please enter a valid 10 digit numeric Driver Licence for Victoria';
    }
  }

  if (validated) {
    try {
      const {
        id_one,
        title_one,
        name_one,
        position_one,
        dateOfBirth_one,
        driverLicense_one,
        email_one,
        mobile_one,
        addressLine1_one,
        addressLine2_one,
        city_one,
        postcode_one,
        country_one,
        state_one,
        id_second,
        title_second,
        name_second,
        position_second,
        dateOfBirth_second,
        driverLicense_second,
        email_second,
        mobile_second,
        addressLine1_second,
        addressLine2_second,
        city_second,
        postcode_second,
        country_second,
        state_second,
      } = data;

      const finalData = {
        stepper: 'director-details',
        applicationId,
        directorDetails: [
          {
            _id: id_one,
            title: title_one,
            name: name_one,
            position: position_one,
            dateOfBirth: dateOfBirth_one,
            driverLicense: driverLicense_one,
            email: email_one,
            mobile: mobile_one,
            addressLine1: addressLine1_one,
            addressLine2: addressLine2_one,
            city: city_one,
            postcode: postcode_one,
            country: country_one,
            state: state_one,
          },
          {
            _id: id_second,
            title: title_second,
            name: name_second,
            position: position_second,
            dateOfBirth: dateOfBirth_second,
            driverLicense: driverLicense_second,
            email: email_second,
            mobile: mobile_second,
            addressLine1: addressLine1_second,
            addressLine2: addressLine2_second,
            city: city_second,
            postcode: postcode_second,
            country: country_second,
            state: state_second,
          },
        ],
      };
      await dispatch(saveApplicationStepDataToBackend(finalData, clientId));
      //   Dispatch Action here to save and draft
      validated = true;
    } catch (e) {
      validated = false;
      throw Error();
    }
  }

  //   dispatch action to display errors here
  dispatch(updateEditApplicationData('directorPrincipalDetails', { errors }));
  return validated;
};
