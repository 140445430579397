import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Input from '../../../../common/Input/Input';
import {
  generateRandomRegistrationNumber,
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';
import Button from '../../../../common/Button/Button';

const PublicApplicationContactDetailsStep = () => {
  const dispatch = useDispatch();

  const contactDetails = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.contactDetails ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const { entityType } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {},
  );

  const onGenerateRegistrationNumber = () => {
    dispatch(generateRandomRegistrationNumber({ requestFrom: 'application' }));
  };

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('contactDetails', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  const onChangeDate = useCallback(
    (name, date) => {
      updateSingleApplicationState(name, date);
    },
    [updateSingleApplicationState],
  );

  const handleCopy = useCallback(() => {
    updateSingleApplicationState('payableFullName', contactDetails?.primaryFullName);
    updateSingleApplicationState('payableEmail', contactDetails?.primaryEmail);
    updateSingleApplicationState('payablePhone', contactDetails?.primaryPhone);
  }, [contactDetails]);

  const INPUTS = useMemo(
    () => [
      {
        type: 'section',
        mainTitle: 'Primary Contact',
      },
      {
        label: 'Full name*',
        placeholder: 'Enter full name',
        type: 'text',
        name: 'primaryFullName',
      },
      {
        label: 'Position*',
        placeholder: 'Enter your position',
        type: 'text',
        name: 'primaryPosition',
      },
      {
        label: 'Email*',
        placeholder: 'your@email.com',
        type: 'text',
        name: 'primaryEmail',
      },
      {
        label: 'Phone',
        placeholder: '1300 048 626',
        type: 'text',
        name: 'primaryPhone',
      },

      {
        type: 'section',
        mainTitle: (
          <div className="d-flex align-center">
            Accounts Payable Contact
            <Button
              title="Copy from Primary Contact"
              buttonType="primary-1"
              className="small-button"
              onClick={handleCopy}
            />
          </div>
        ),
      },

      {
        label: 'Full name*',
        placeholder: 'Enter full name',
        type: 'text',
        name: 'payableFullName',
      },
      {
        label: 'Email*',
        placeholder: 'your@email.com',
        type: 'text',
        name: 'payableEmail',
      },
      {
        label: 'Phone',
        placeholder: '1300 048 626',
        type: 'text',
        name: 'payablePhone',
      },
    ],
    [entityType, onGenerateRegistrationNumber, contactDetails?.notFoundAddress, _id, handleCopy],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={contactDetails[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={() => {}}
              placeholder={input.placeholder}
              value={contactDetails?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={contactDetails?.[input?.name] ?? []}
              onChange={handleSelectInputChange}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'date':
          component = (
            <div className="date-picker-container">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={input.placeholder}
                selected={contactDetails?.[input?.name] && new Date(contactDetails?.[input?.name])}
                onChange={date => onChangeDate(input?.name, date)}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                popperProps={{ positionFixed: true }}
              />
              <span className="material-icons-round">event</span>
            </div>
          );
          break;
        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.name}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          <div>
            {component}
            {contactDetails?.errors?.[input?.name] && (
              <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>{contactDetails?.errors?.[input?.name]}</div>
            )}
          </div>
        </React.Fragment>
      );
    },
    [contactDetails, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }
    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationContactDetailsStep;
