import { APPLICATION_REDUX_CONSTANTS } from './PublicApplicationReduxConstants';
import { FIELD_NAME_BY_ENTITY } from '../../../constants/EntitySearchConstants';

const initialApplicationList = {
  clientDetail: {},
  editApplication: {
    applicationId: '',
    applicationStage: 0,
    _id: '',
    country: {
      label: 'Australia',
      name: 'country',
      value: 'AUS',
    },
    businessDetails: {
      salesRepName: '',
      typeOfAccount: [],
      abn: '',
      acn: '',
      businessStructure: [],
      buisnessStartDate: '',
      newOrExistingApplicant: [],
      reference: [],
      legalEntityName: '',
      mainTradingName: '',
      otherTradingNames: '',
      estimatedMonthlyPurchases: '',
      customerGroup: '',
      homeBranch: [],
      offerCode: '',
    },
    contactDetails: {
      primaryFullName: '',
      primaryPosition: '',
      primaryEmail: '',
      primaryPhone: '',
      payableFullName: '',
      payableEmail: '',
      payablePhone: '',
    },
    businessAddressesDetails: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      country: '',
      state: '',
    },
    directorPrincipalDetails: {
      title_one: '',
      name_one: '',
      position_one: '',
      dateOfBirth_one: '',
      driverLicense_one: '',
      email_one: '',
      mobile_one: '',
      addressLine1_one: '',
      addressLine2_one: '',
      city_one: '',
      postcode_one: '',
      country_one: '',
      state_one: '',
      title_second: '',
      name_second: '',
      position_second: '',
      dateOfBirth_second: '',
      driverLicense_second: '',
      email_second: '',
      mobile_second: '',
      addressLine1_second: '',
      addressLine2_second: '',
      city_second: '',
      postcode_second: '',
      country_second: '',
      state_second: '',
    },
    creditLimit: {
      isExtendedPaymentTerms: false,
      extendedPaymentTermsDetails: '',
      isPassedOverdueAmount: false,
      passedOverdueDetails: '',
      creditLimit: '',
      outstandingAmount: '',
      orderOnHand: '',
      note: '',
      id: '',
      errors: {},
    },

    documents: {
      directorIdentificationNumber: '',
    },
    partners: [],
  },

  companyData: {
    dropdownData: {
      debtors: [],
      streetType: [],
      australianStates: [],
      newZealandStates: [],
      entityType: [],
      countryList: [],
    },
    entityNameSearch: {
      isLoading: false,
      error: false,
      errorMessage: '',
      data: [],
    },
  },
};

export const publicApplicationReplicated = (state = initialApplicationList, action) => {
  switch (action.type) {
    case APPLICATION_REDUX_CONSTANTS.CLIENT_DATA:
      return {
        ...state,
        clientDetail: action?.data,
      };

    case APPLICATION_REDUX_CONSTANTS.APPLICATION_DETAILS:
      return {
        ...state,
        editApplication: { ...state?.editApplication, ...action?.data },
      };

    case APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_DETAILS_ON_BACK_TO_COMPANY_STEP:
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          ...action?.data,
          applicationStage: action.activeStep - 1,
        },
      };

    // Company step
    case APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_DROP_DOWN_DATA: {
      const dropdownData = { ...state?.companyData?.dropdownData };
      Object.entries(action?.data)?.forEach(([key, value]) => {
        dropdownData[key] = value?.data?.map(entity => ({
          label: entity?.name,
          name: value?.field,
          value: entity?._id,
        }));
      });
      const companyData = {
        ...state?.companyData,
        dropdownData,
      };

      return {
        ...state,
        companyData,
      };
    }

    case APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_ENTITY_TYPE_DATA: {
      let entityNameSearchData = state?.companyData?.entityNameSearch?.data ?? [];
      let hasNoMoreRecords = false;

      if (action?.data?.data) {
        entityNameSearchData = [...entityNameSearchData, ...action?.data?.data];

        if (state?.companyData?.entityNameSearch?.data?.length === entityNameSearchData?.length)
          hasNoMoreRecords = true;
      }

      return {
        ...state,
        companyData: {
          ...state?.companyData,
          entityNameSearch: {
            ...state?.companyData?.entityNameSearch,
            data: entityNameSearchData,
            hasMoreData: !hasNoMoreRecords,
            isLoading: action?.data?.isLoading,
            error: action?.data?.error,
            errorMessage: action?.data?.errorMessage,
          },
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.COMPANY.WIPE_OUT_ENTITY_TABLE_DATA:
      return {
        ...state,
        companyData: {
          ...state?.companyData,
          entityNameSearch: {
            isLoading: false,
            error: false,
            errorMessage: '',
            data: [],
            hasMoreData: false,
          },
        },
      };

    // edit application
    case APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.APPLICATION_COMPANY_EDIT_APPLICATION_CHANGE_FIELD_VALUE: {
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          [action?.name]: action?.value,
        },
      };
    }
    case APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.APPLICATION_COMPANY_EDIT_APPLICATION_RESET_DATA: {
      return {
        ...state,
        editApplication: {
          ...initialApplicationList?.editApplication,
        },
      };
    }
    case APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.APPLICATION_COMPANY_EDIT_APPLICATION_UPDATE_ALL_DATA: {
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          [action?.stepName]: { ...state?.editApplication?.[action?.stepName], ...action?.data },
        },
      };
    }
    case APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.REPLICATED_APPLICATION_UPDATE_FIELD: {
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          [action?.stepName]: {
            ...state?.editApplication?.[action?.stepName],
            [action?.name]: action?.value,
          },
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.PERSON.ADD_APPLICATION_PERSON: {
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          partners: [...(state?.editApplication?.partners ?? []), action?.data],
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.PERSON.REMOVE_APPLICATION_PERSON: {
      const perStep = state?.editApplication?.partners;
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          partners: perStep.filter((e, i) => i !== action?.data),
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.PERSON.WIPE_OUT_PERSON_STEP_DATA: {
      // const perStep = state?.editApplication.partners;
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          partners: action?.data,
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.PERSON.EDIT_APPLICATION_PERSON: {
      const partners = [...state?.editApplication?.partners];
      partners[action?.index] = {
        ...partners?.[action?.index],
        [action?.name]: action?.value,
      };

      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          partners,
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.PERSON.PERSON_STEP_COMPANY_EDIT_APPLICATION_UPDATE_ALL_DATA: {
      const partners = [...state?.editApplication?.partners];
      partners[action?.index] = {
        ...partners[action?.index],
        tradingName: action?.data?.tradingName ?? '',
        entityType: action?.data?.entityType ?? '',
        entityName: action?.data?.entityName ?? '',
        abn: action?.data?.abn ?? '',
        acn: action?.data?.acn ?? '',
      };

      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          partners,
        },
      };
    }

    case APPLICATION_REDUX_CONSTANTS.PERSON.CHANGE_APPLICATION_PERSON_TYPE: {
      const partners = [...state?.editApplication?.partners];
      partners[action?.index] = {
        ...partners[action?.index],
        type: action?.personType,
      };

      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          partners,
        },
      };
    }

    // Documents
    case APPLICATION_REDUX_CONSTANTS.DOCUMENTS.DOCUMENT_TYPE_LIST_DATA:
      return {
        ...state,
        editApplication: {
          ...state?.editApplication,
          documents: {
            ...state?.editApplication?.documents,
            documentTypeList: action?.data,
          },
        },
      };

    case APPLICATION_REDUX_CONSTANTS.DOCUMENTS.APPLICATION_DOCUMENT_GET_UPLOAD_DOCUMENT_DATA: {
      const editApplication = { ...state?.editApplication };
      const documents = { ...editApplication?.documents };
      const uploadDocumentApplicationData = [...action?.data];

      return {
        ...state,
        editApplication: {
          ...editApplication,
          documents: {
            ...documents,
            uploadDocumentApplicationData,
          },
        },
      };
    }
    // case APPLICATION_REDUX_CONSTANTS.DOCUMENTS.UPLOAD_DOCUMENT_DATA: {
    //   const editApplication = { ...state?.editApplication };
    //   const documents = { ...editApplication?.documents };
    //   const uploadDocumentApplicationData = documents?.uploadDocumentApplicationData
    //     ? { ...documents?.uploadDocumentApplicationData }
    //     : {};
    //
    //
    //   return {
    //     ...state,
    //     editApplication: {
    //       ...editApplication,
    //       documents: {
    //         ...documents,
    //         uploadDocumentApplicationData: {
    //           ...uploadDocumentApplicationData,
    //           [action?.data?.documentTypeId]: action?.data,
    //         },
    //       },
    //     },
    //   };
    // }

    case APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_SEARCH_DROP_DOWN_DATA: {
      const name = FIELD_NAME_BY_ENTITY[action?.name];
      const dropdownData = {
        ...state?.companyData?.dropdownData,
        [action?.name]: action?.data?.map(entity => ({
          label: entity.name,
          name,
          value: entity._id,
        })),
      };
      const companyData = {
        ...state?.companyData,
        dropdownData,
      };

      return {
        ...state,
        companyData,
      };
    }

    default:
      return state;
  }
};
