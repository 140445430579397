import { NUMBER_REGEX } from '../../../../../constants/RegexConstants';
import { saveApplicationStepDataToBackend, updateEditApplicationData } from '../../../redux/PublicApplicationAction';

export const publicApplicationContactDetailsStepValidation = async (dispatch, data, applicationId, clientId) => {
  const errors = {};
  let validated = true;

  // For first name
  if (!data?.primaryFullName || data?.primaryFullName?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.primaryFullName = 'Please enter full name';
  }
  // For primary Position
  if (!data?.primaryPosition || data?.primaryPosition?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.primaryPosition = 'Please enter position';
  }

  // For Primary email
  if (!data?.primaryEmail || data?.primaryEmail?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.primaryEmail = 'Please enter email';
  }

  if (data?.primaryEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.primaryEmail)) {
    validated = false;
    errors.primaryEmail = 'Please enter valid email';
  }

  if (data?.primaryPhone && !NUMBER_REGEX.test(data?.primaryPhone?.toString()?.trim())) {
    validated = false;
    errors.primaryPhone = 'Phone number should be number';
  }
  // For Secondary name
  if (!data?.payableFullName || data?.payableFullName?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.payableFullName = 'Please enter full name';
  }

  // For payableEmail
  if (!data?.payableEmail || data?.payableEmail?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.payableEmail = 'Please enter email';
  }

  if (data?.payableEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.payableEmail)) {
    validated = false;
    errors.payableEmail = 'Please enter valid email';
  }

  if (data?.payablePhone && !NUMBER_REGEX.test(data?.payablePhone?.toString()?.trim())) {
    validated = false;
    errors.payablePhone = 'Phone number should be number';
  }

  if (validated) {
    try {
      const {
        primaryFullName,
        primaryPosition,
        primaryEmail,
        primaryPhone,
        payableFullName,
        payableEmail,
        payablePhone,
      } = data;

      const finalData = {
        stepper: 'contact-details',
        applicationId,
        primaryFullName,
        primaryPosition,
        primaryEmail,
        primaryPhone,
        payableFullName,
        payableEmail,
        payablePhone,
      };
      await dispatch(saveApplicationStepDataToBackend(finalData, clientId));
      //   Dispatch Action here to save and draft
      validated = true;
    } catch (e) {
      validated = false;
      throw Error();
    }
  }

  //   dispatch action to display errors here
  dispatch(updateEditApplicationData('contactDetails', { errors }));
  return validated;
};
