import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../common/Input/Input';
import {
  getApplicationDocumentDataList,
  getDocumentTypeList,
  updateEditApplicationField,
  uploadDocument,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import FileUpload from '../../../../common/Header/component/FileUpload';
import { useQueryParams } from '../../../../hooks/GetQueryParamHook';

const PublicApplicationAdditionalDocumentsStep = () => {
  const dispatch = useDispatch();

  const { applicationId } = useQueryParams();

  const { documentTypeList, ...documents } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.documents ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const INPUTS = useMemo(
    () => [
      {
        mainTitle: 'Documents',
        type: 'section',
      },
      {
        title:
          'Please download Deed Poll of guarantee and indemnity form, sign and upload into the below box. Form available here',
        type: 'title',
      },
      { label: 'Please upload your documents here', type: 'fileUpload', name: 'Guarantee and Indemnity' },
      {
        type: 'line',
      },
      {
        title: 'Please upload a copy of Drivers Licence or Passport *',
        type: 'title',
      },

      {
        label: 'Please upload your Drivers Licence or Passport here *',
        type: 'fileUpload',
        name: 'Driving License or Passport',
      },

      {
        type: 'line',
      },
      {
        label: 'Director Identification Number',
        title: 'Director Identification Number',
        type: 'text',
        placeholder: "Enter Director's Identification Number",
        name: 'directorIdentificationNumber',
      },
    ],
    [document?.notFoundAddress, _id],
  );

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('documents', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  // Retrieves document ID by title, utilized exclusively for including document type in form data submissions
  function getDocumentIdByTitle(title) {
    const document = documentTypeList?.find(doc => doc.documentTitle === title);

    if (document) {
      return document;
    }
    return null;
  }

  const onUploadClick = useCallback(
    async (name, e) => {
      // e.persist();
      if (e.target.files && e.target.files.length > 0) {
        const fileExtension = ['pdf'];
        const mimeType = ['application/pdf'];

        const checkExtension =
          fileExtension.indexOf(e.target.files[0].name.split('.').splice(-1)[0].toLowerCase()) !== -1;
        const checkMimeTypes = mimeType.indexOf(e.target.files[0].type) !== -1;

        const checkFileSize = e.target.files[0].size > 10485760;
        if (!(checkExtension || checkMimeTypes)) {
          errorNotification('Invalid File Type. Please upload a PDF file only.');
        } else if (checkFileSize) {
          errorNotification('File size should be less than 10MB.');
        } else {
          // updateSingleApplicationState(name, e.target.files[0].name);

          const documentID = getDocumentIdByTitle(name) !== null ? getDocumentIdByTitle(name)?._id : null;

          if (documentID) {
            const formData = new FormData();
            formData.append('documentType', documentID);
            formData.append('document', e.target.files[0]);
            formData.append('entityId', applicationId);
            formData.append('documentFor', 'application');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            await dispatch(uploadDocument(formData, config, _id, applicationId));
          } else {
            errorNotification('Something went wrong');
          }
        }
      }
    },
    [documentTypeList, _id, applicationId, dispatch],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;
      // Dynamically get documentTitle based on the input name
      const fileTypeTitle = getDocumentIdByTitle(input?.name)?.documentTitle;

      // Retrieve file name based on the documentTypeId matching documentTitle
      const fileName = documents?.uploadDocumentApplicationData?.find(
        e => e?.documentTypeId === fileTypeTitle,
      )?.originalFileName;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={documents[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'fileUpload': {
          component = (
            <FileUpload
              isProfile={false}
              fileName={fileName ?? 'Browse...'}
              handleChange={e => onUploadClick(input?.name, e)}
            />
          );
          break;
        }

        case 'title': {
          component = <div className="application-company-step-title">{input?.title}</div>;
          break;
        }

        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.label}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          {component}
          {documents?.errors?.[input?.name] && (
            <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>{documents?.errors?.[input?.name]}</div>
          )}
        </React.Fragment>
      );
    },
    [
      documents,
      handleSelectInputChange,
      handleTextInputChange,
      updateSingleApplicationState,
      onUploadClick,
      documentTypeList,
    ],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getDocumentTypeList(_id));
      if (applicationId) {
        dispatch(getApplicationDocumentDataList(applicationId, _id));
      }
    }
  }, [_id, applicationId]);

  return (
    <>
      <div className="application-company-container ">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationAdditionalDocumentsStep;
