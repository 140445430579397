import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Input from '../../../../common/Input/Input';
import {
  getApplicationCompanyDataFromABNOrACN,
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';
import { applicationErrorHelper } from '../../../../helpers/applicationErrorHelper';
import { displayErrors } from '../../../../helpers/ErrorNotifyHelper';

const PublicApplicationBusinessDetailsStep = () => {
  const prevRef = useRef({});

  const dispatch = useDispatch();
  const businessDetails = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.businessDetails ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const { entityType } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {},
  );
  const { country } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication ?? {},
  );

  const INPUTS = useMemo(
    () => [
      {
        type: 'section',
        mainTitle: 'Business',
      },
      {
        label: 'Sales Rep Name',
        placeholder: 'Sales Rep Name',
        type: 'text',
        name: 'salesRepName',
      },
      {
        label: 'ABN/NZBN*',
        placeholder: '123 456 789 01',
        type: 'search',
        name: 'abn',
        data: [],
      },
      {
        label: 'ACN/NCN',
        placeholder: '123 456 789',
        type: 'search',
        name: 'acn',
        data: [],
      },
      {
        label: 'Business Structure*',
        placeholder: 'Business Structure',
        type: 'select',
        name: 'businessStructure',
        data: entityType?.map(e => ({ ...e, name: 'businessStructure' })),
      },
      {
        label: 'Business Start Date',
        placeholder: 'Choose date',
        type: 'date',
        name: 'buisnessStartDate',
      },
      {
        label: 'Are You a New or Existing Customer?*',
        placeholder: 'New or an Existing Customer?',
        type: 'select',
        name: 'newOrExistingApplicant',
        data: [
          { label: 'New Applicant', name: 'newOrExistingApplicant', value: 'NEW_APPLICANT' },
          {
            label: 'Existing Account',
            name: 'newOrExistingApplicant',
            value: 'EXISTING_ACCOUNT',
          },
        ],
      },
      {
        type: 'section',
        mainTitle: 'Names',
      },
      {
        label: 'Legal Entity Name *',
        placeholder: 'Legal Entity Name',
        type: 'text',
        name: 'legalEntityName',
      },
      {
        label: 'Main Trading Name *',
        placeholder: 'Main Trading Name',
        type: 'text',
        name: 'mainTradingName',
      },
      {
        label: 'Other Trading Names',
        placeholder: 'Other Trading Names',
        type: 'text',
        name: 'otherTradingNames',
      },
      {
        type: 'section',
        mainTitle: 'Account Details',
      },
      {
        label: 'Estimated Monthly Purchases *',
        placeholder: 'Estimated Monthly Purchases',
        type: 'text',
        name: 'estimatedMonthlyPurchases',
      },
    ],
    [entityType, businessDetails?.notFoundAddress, _id],
  );

  const finalInputs = useMemo(() => {
    const filteredData = [...INPUTS];
    if (businessDetails?.newOrExistingApplicant?.value) {
      const isExitingApplicant = businessDetails?.newOrExistingApplicant?.value === 'EXISTING_ACCOUNT';
      if (isExitingApplicant) {
        filteredData.splice(7, 0, {
          label: 'Please Provide Your Customer Number',
          placeholder: `If You're an Existing Customer Please Provide Your Customer Number`,
          type: 'text',
          name: 'customerNumber',
        });
      } else {
        filteredData.splice(7, 0, {
          label: `How Did You Hear About us?`,
          placeholder: 'How Did You Hear About us?',
          type: 'select',
          name: 'reference',
          data: [
            { label: 'Account manager', name: 'reference', value: 'accountManager' },
            { label: 'Recommendation', name: 'reference', value: 'recommendation' },
            { label: 'Trade store or truss and frame plant', name: 'reference', value: 'tradeStore' },
            { label: 'Newspaper/magazine', name: 'reference', value: 'newspaperMagazine' },
            { label: 'Google', name: 'reference', value: 'google' },
            { label: 'Event', name: 'reference', value: 'event' },
            { label: 'Billboard', name: 'reference', value: 'billboard' },
            { label: 'Radio', name: 'reference', value: 'radio' },
          ],
        });
      }
    }
    return filteredData;
  }, [INPUTS, businessDetails]);

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('businessDetails', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  const onChangeDate = useCallback(
    (name, date) => {
      updateSingleApplicationState(name, date);
    },
    [updateSingleApplicationState],
  );

  const updateCompanyState = useCallback(data => {
    dispatch(updateEditApplicationData('businessDetails', data));
  }, []);

  const handleApplicationErrors = useCallback(response => {
    const { isModal, modalType, resData } = applicationErrorHelper(response);
    if (isModal && modalType === 'ERROR') {
      return false;
    }
    if (isModal && modalType === 'WARNING') {
      return { resData, isModal };
    }
    return { resData };
  }, []);

  const updateSingleCompanyState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('businessDetails', name, value));
  }, []);

  const handleSearchTextInputOnSearchClick = useCallback(
    async ref => {
      try {
        if (ref?.value?.trim()?.length > 0) {
          const searchString = ref?.value;
          const params = {
            searchString,
            country: country?.value,
            clientId: _id,
          };
          const response = await dispatch(getApplicationCompanyDataFromABNOrACN(params));

          const { resData } = handleApplicationErrors(response);
          if (resData) {
            const updatedRes = {
              ...resData,
              legalEntityName: resData?.entityName?.label,
              businessStructure: entityType?.find(e => e?.value === resData?.entityType?.value),
              mainTradingName: resData?.tradingName,
              buisnessStartDate: resData?.registeredDate ? new Date(resData?.registeredDate) : null,
            };

            updateCompanyState(updatedRes);
            prevRef.current = {
              ...prevRef.current,
              acn: resData?.acn,
              abn: resData?.abn,
            };
          }
        } else {
          errorNotification(`Please enter search text for ${ref?.name}`);
        }
      } catch (err) {
        let value = prevRef?.current?.abn;
        if (ref?.name === 'acn') value = prevRef?.current?.acn;
        updateSingleCompanyState(ref?.name, value);
        displayErrors(err);
        // handleApplicationErrors(err?.response);
      }
    },
    [country, updateCompanyState, updateSingleCompanyState, prevRef.current, _id, entityType],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={businessDetails[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={handleSearchTextInputOnSearchClick}
              placeholder={input.placeholder}
              value={businessDetails?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={businessDetails?.[input?.name] ?? []}
              onChange={handleSelectInputChange}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'date':
          component = (
            <div className="date-picker-container">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={input.placeholder}
                selected={
                  businessDetails?.[input?.name] && moment(businessDetails?.[input?.name], 'DD-MM-YYYY').isValid()
                    ? moment(businessDetails?.[input?.name], 'DD-MM-YYYY').toDate()
                    : null
                }
                onChange={date => {
                  const formattedDate = moment(date).format('DD-MM-YYYY');
                  onChangeDate(input?.name, formattedDate);
                }}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                popperProps={{ positionFixed: true }}
              />
              <span className="material-icons-round">event</span>
            </div>
          );
          break;
        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.label}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          <div>
            {component}
            {businessDetails?.errors?.[input?.name] && (
              <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>{businessDetails?.errors?.[input?.name]}</div>
            )}
          </div>
        </React.Fragment>
      );
    },
    [businessDetails, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }
    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container">{finalInputs?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationBusinessDetailsStep;
