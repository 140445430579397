import { SPECIAL_CHARACTER_REGEX } from '../../../../../constants/RegexConstants';
import { saveApplicationStepDataToBackend, updateEditApplicationData } from '../../../redux/PublicApplicationAction';

export const publicApplicationBusinessAddressesDetailsStepValidation = async (
  dispatch,
  data,
  applicationId,
  clientId,
) => {
  const errors = {};
  let validated = true;

  // For addressLine1
  if (!data?.addressLine1 || data?.addressLine1?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.addressLine1 = 'Please enter addressLine1';
  }

  // For city
  if (!data?.city || data?.city?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.city = 'Please enter city';
  }
  // For postcode
  if (!data?.postcode || data?.postcode?.toString()?.trim()?.length <= 0) {
    validated = false;
    errors.postcode = 'Please enter postcode';
  }
  // For country
  if (!data?.country || data?.country?.length <= 0) {
    validated = false;
    errors.country = 'Please select country before continue';
  }
  if (['AUS', 'NZL'].includes(data?.country?.value) && !data?.state?.value) {
    validated = false;
    errors.state = 'Please select state';
  }
  if (
    !['AUS', 'NZL'].includes(data?.country?.value) &&
    (!data?.state || SPECIAL_CHARACTER_REGEX.test(data?.state?.toString()?.trim()))
  ) {
    validated = false;
    errors.state = 'Please enter valid state';
  }
  if (validated) {
    try {
      const { addressLine1, addressLine2, city, postcode, country, state } = data;

      const finalData = {
        stepper: 'business-addresses',
        applicationId,
        addressLine1,
        addressLine2,
        city,
        postcode,
        country,
        state,
      };
      await dispatch(saveApplicationStepDataToBackend(finalData, clientId));
      //   Dispatch Action here to save and draft
      validated = true;
    } catch (e) {
      validated = false;
      throw Error();
    }
  }

  //   dispatch action to display errors here
  dispatch(updateEditApplicationData('businessAddressesDetails', { errors }));
  return validated;
};
