import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Input from '../../../../common/Input/Input';
import {
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';

const PublicApplicationBusinessAddressesDetailsStep = () => {
  const dispatch = useDispatch();

  const businessAddressesDetails = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.businessAddressesDetails ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const {
    australianStates = [],
    newZealandStates = [],
    entityType,
    countryList,
  } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {});

  const isAusOrNewz = useMemo(
    () => ['Australia', 'New Zealand'].includes(businessAddressesDetails?.country?.label),
    [businessAddressesDetails],
  );

  const INPUTS = useMemo(
    () => [
      {
        type: 'section',
        mainTitle: 'Business Addresses',
      },
      {
        label: 'Address Line 1*',
        placeholder: 'Enter your address line 1',
        type: 'text',
        name: 'addressLine1',
      },
      {
        label: 'Address Line 2',
        placeholder: 'Enter your address line 2',
        type: 'text',
        name: 'addressLine2',
      },
      {
        label: 'City*',
        placeholder: 'Enter your city',
        type: 'text',
        name: 'city',
      },
      {
        label: 'Postcode*',
        placeholder: 'Enter your postcode',
        type: 'text',
        name: 'postcode',
      },
      {
        label: 'Country*',
        placeholder: 'Select your country',
        type: 'select',
        name: 'country',
        data: countryList,
      },
      {
        label: 'State *',
        placeholder: isAusOrNewz ? 'Select' : 'Enter your state',
        type: isAusOrNewz ? 'select' : 'text',
        name: 'state',
        data: businessAddressesDetails?.country?.label === 'Australia' ? australianStates : newZealandStates,
      },
    ],
    [
      entityType,
      businessAddressesDetails?.notFoundAddress,
      _id,
      isAusOrNewz,
      countryList,
      businessAddressesDetails?.country?.label,
    ],
  );

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('businessAddressesDetails', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      if (data?.name === 'country') {
        updateSingleApplicationState('state', undefined);
      }
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  const onChangeDate = useCallback(
    (name, date) => {
      updateSingleApplicationState(name, date);
    },
    [updateSingleApplicationState],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={businessAddressesDetails[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={() => {}}
              placeholder={input.placeholder}
              value={businessAddressesDetails?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={businessAddressesDetails?.[input?.name] ?? []}
              onChange={handleSelectInputChange}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'date':
          component = (
            <div className="date-picker-container">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={input.placeholder}
                selected={businessAddressesDetails?.[input?.name] && new Date(businessAddressesDetails?.[input?.name])}
                onChange={date => onChangeDate(input?.name, date)}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                popperProps={{ positionFixed: true }}
              />
              <span className="material-icons-round">event</span>
            </div>
          );
          break;
        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.label}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          <div>
            {component}
            {businessAddressesDetails?.errors?.[input?.name] && (
              <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>
                {businessAddressesDetails?.errors?.[input?.name]}
              </div>
            )}
          </div>
        </React.Fragment>
      );
    },
    [businessAddressesDetails, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }

    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationBusinessAddressesDetailsStep;
