export const CLIENT_REDUX_CONSTANTS = {
  CLIENT_DATA: 'CLIENT_DATA',
  CLIENT_DATA_TERMS_AND_CONDITIONS: 'CLIENT_DATA_TERMS_AND_CONDITIONS',
  CLIENT_DATA_URL: 'CLIENT_DATA_URL',
  CLIENT_DATA_DOCUMENT_TYPE: 'CLIENT_DATA_DOCUMENT_TYPE',
  CLIENT_POLICY_DATA: 'CLIENT_POLICY_DATA',
  CLIENT_POLICY_COLUMN_LIST: 'CLIENT_POLICY_COLUMN_LIST',
  CLIENT_POLICY_DEFAULT_COLUMN_LIST: 'CLIENT_POLICY_DEFAULT_COLUMN_LIST',
  CLIENT_POLICY_UPDATE_COLUMN_LIST: 'CLIENT_POLICY_UPDATE_COLUMN_LIST',
  RESET_CLIENT_DATA: 'RESET_CLIENT_DATA',
};
