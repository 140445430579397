/* eslint-disable object-shorthand */
import { saveApplicationStepDataToBackend, updateEditApplicationData } from '../../../redux/PublicApplicationAction';
import { NUMBER_REGEX } from '../../../../../constants/RegexConstants';

export const publicApplicationBusinessDetailsStepValidation = async (dispatch, data, applicationId, clientId) => {
  const errors = {};
  let validated = true;
  try {
    // For sales rep name
    if (data?.salesRepName && data?.salesRepName?.trim().length > 20) {
      validated = false;
      errors.salesRepName = 'Please enter valid sales rep name';
    }
    // For ABN
    if (!data?.abn || data?.abn?.toString()?.trim()?.length <= 0) {
      validated = false;
      errors.abn = 'Please enter ABN number before continue';
    }
    if (
      data?.abn &&
      (!NUMBER_REGEX.test(data?.abn?.trim()) || data?.abn?.trim()?.length < 11 || data?.abn?.trim().length > 13)
    ) {
      validated = false;
      errors.abn = 'Please enter valid ABN number';
    }
    if (
      data?.acn &&
      (!NUMBER_REGEX.test(data?.acn?.toString()?.trim()) ||
        data?.acn?.trim()?.length < 5 ||
        data?.acn?.trim().length > 9)
    ) {
      validated = false;
      errors.acn = 'Please enter valid ACN number';
    }
    // For business structure
    if (!data?.businessStructure.value) {
      validated = false;
      errors.businessStructure = 'Please select business structure';
    }

    // For new or existing applicant
    if (!data?.newOrExistingApplicant || data?.newOrExistingApplicant?.length <= 0) {
      validated = false;
      errors.newOrExistingApplicant = 'Please select new or existing applicant';
    }
    // For customer number
    if (data?.customerNumber?.length > 20) {
      validated = false;
      errors.customerNumber = 'Please enter valid customer number';
    }

    // For reference
    if (!data?.reference) {
      validated = false;
      errors.reference = 'Please enter reference before continue';
    }

    // For legal entity name
    if (!data?.legalEntityName) {
      validated = false;
      errors.legalEntityName = 'Please enter legal entity name before continue';
    }
    // For main trading name
    if (!data?.mainTradingName) {
      validated = false;
      errors.mainTradingName = 'Please enter main trading name before continue';
    }

    // For estimated monthly purchases
    if (!data?.estimatedMonthlyPurchases || !NUMBER_REGEX.test(data?.estimatedMonthlyPurchases?.toString()?.trim())) {
      validated = false;
      errors.estimatedMonthlyPurchases = 'Please enter valid estimated monthly purchases before continue';
    }

    if (validated) {
      const {
        salesRepName,
        abn,
        acn,
        businessStructure,
        buisnessStartDate,
        newOrExistingApplicant,
        reference,
        legalEntityName,
        mainTradingName,
        otherTradingNames,
        estimatedMonthlyPurchases,
        customerNumber,
      } = data;

      const finalData = {
        stepper: 'business-details',
        salesRepName: salesRepName,
        abn: abn,
        acn: acn,
        entityType: businessStructure?.value,
        buisnessStartDate: buisnessStartDate,
        newOrExistingApplicant: newOrExistingApplicant.value,
        customerNumber: newOrExistingApplicant.value ? customerNumber : undefined,
        referenceSource: reference.value,
        entityName: legalEntityName?.trim()?.length > 0 ? legalEntityName : undefined,
        tradingName: mainTradingName?.trim()?.length > 0 ? mainTradingName : undefined,
        otherTradingName: otherTradingNames?.trim()?.length > 0 ? otherTradingNames : undefined,
        estimatedMonthlyPurchases:
          estimatedMonthlyPurchases?.trim()?.length > 0 ? estimatedMonthlyPurchases : undefined,
        applicationId: applicationId,
      };
      await dispatch(saveApplicationStepDataToBackend(finalData, clientId));
      //   Dispatch Action here to save and draft
      validated = true;
    }
  } catch (e) {
    validated = false;
    throw Error();
  }

  //   dispatch action to display errors here
  dispatch(updateEditApplicationData('businessDetails', { errors }));
  return validated;
};
