import CompanyProfileApiService from '../services/CompanyProfileApiService';
import { errorNotification, successNotification } from '../../../common/Toast';
import { CLIENT_REDUX_CONSTANTS } from './CompanyProfileReduxConstants';
import { displayErrors } from '../../../helpers/ErrorNotifyHelper';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../../../common/GeneralLoader/redux/GeneralLoaderAction';
import { APPLICATION_REDUX_CONSTANTS } from '../../PublicApplication/redux/PublicApplicationReduxConstants';

export const getDocumentType = () => {
  return async dispatch => {
    try {
      const response = await CompanyProfileApiService.getDocumentType();
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: CLIENT_REDUX_CONSTANTS.CLIENT_DATA_DOCUMENT_TYPE,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getClientDetails = () => {
  return async dispatch => {
    startGeneralLoaderOnRequest('viewCompanyProfilePageLoaderAction');
    try {
      const response = await CompanyProfileApiService.getClientData();
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: CLIENT_REDUX_CONSTANTS.CLIENT_DATA,
          data: response?.data?.data,
        });
        dispatch(getDocumentType());

        stopGeneralLoaderOnSuccessOrFail('viewCompanyProfilePageLoaderAction');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('viewCompanyProfilePageLoaderAction');
      displayErrors(e);
    }
  };
};

export const getAnonymousClientDetails = clientId => {
  return async dispatch => {
    startGeneralLoaderOnRequest('getPublicClientDetailsActionLoader');
    try {
      const response = await CompanyProfileApiService.getAnonymousClientData(clientId);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.CLIENT_DATA,
          data: response?.data?.data,
        });
        stopGeneralLoaderOnSuccessOrFail('getPublicClientDetailsActionLoader');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('getPublicClientDetailsActionLoader');
      displayErrors(e);
    }
  };
};

export const getCompanyProfilePolicyList = (params = { page: 1, limit: 15 }) => {
  return async dispatch => {
    try {
      const response = await CompanyProfileApiService.getClientPolicyList(params);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: CLIENT_REDUX_CONSTANTS.CLIENT_POLICY_DATA,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response?.data?.status === undefined) {
          errorNotification('It seems like server is down, Please try again later.');
        } else {
          errorNotification('Internal server error.');
        }
      }
    }
  };
};

export const getCompanyProfilePolicyColumnList = () => {
  return async dispatch => {
    try {
      const response = await CompanyProfileApiService.getClientPolicyColumnList();
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: CLIENT_REDUX_CONSTANTS.CLIENT_POLICY_COLUMN_LIST,
          data: response?.data?.data,
        });
        dispatch({
          type: CLIENT_REDUX_CONSTANTS.CLIENT_POLICY_DEFAULT_COLUMN_LIST,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response?.data?.status === undefined) {
          errorNotification('It seems like server is down, Please try again later.');
        } else {
          errorNotification('Internal server error.');
        }
      }
    }
  };
};

export const changeCompanyProfilePolicyColumnList = data => {
  return dispatch => {
    dispatch({
      type: CLIENT_REDUX_CONSTANTS.CLIENT_POLICY_UPDATE_COLUMN_LIST,
      data,
    });
  };
};

export const deleteDocument = documentId => {
  return async dispatch => {
    startGeneralLoaderOnRequest('deleteDocumentActionLoader');

    if (!documentId) {
      errorNotification('Invalid document ID. Please try again.');
      stopGeneralLoaderOnSuccessOrFail('deleteDocumentActionLoader');
      return;
    }

    try {
      const response = await CompanyProfileApiService.deleteTermsAndConditionsDocument(documentId);
      if (response?.data?.status === 'SUCCESS') {
        dispatch(getClientDetails());
        successNotification('The document has been deleted successfully.');
        stopGeneralLoaderOnSuccessOrFail('deleteDocumentActionLoader');
      } else {
        errorNotification('Failed to delete the document. Please try again.');
        stopGeneralLoaderOnSuccessOrFail('deleteDocumentActionLoader');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('deleteDocumentActionLoader');
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('deleteDocumentActionLoader');
    }
  };
};

export const handleUploadTermsAndConditions = (documentTypes, document, entityId) => {
  return async dispatch => {
    startGeneralLoaderOnRequest('UploadTermsAndConditionsActionLoader');

    const docType = documentTypes?.find(item => item.documentTitle === 'Terms And Conditions');
    const formData = new FormData();
    formData.append('documentType', docType?._id);
    formData.append('document', document);
    formData.append('description', 'Terms And Conditions Document');
    formData.append('documentFor', 'client');
    formData.append('entityId', entityId);

    try {
      const response = await CompanyProfileApiService.uploadTermsAndConditions(formData);
      if (response?.data?.status === 'SUCCESS') {
        dispatch(getClientDetails());
        successNotification('Terms and conditions updated successfully');
        stopGeneralLoaderOnSuccessOrFail('UploadTermsAndConditionsActionLoader');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('UploadTermsAndConditionsActionLoader');
      displayErrors(e);
    }
  };
};

export const handleUploadProfileLogo = document => {
  return async dispatch => {
    startGeneralLoaderOnRequest('uploadLogoActionLoader');

    const formData = new FormData();
    formData.append('logo', document);

    try {
      const response = await CompanyProfileApiService.uploadProfileLogo(formData);
      console.log({ response });
      if (response?.data?.status === 'SUCCESS') {
        successNotification('Profile Logo uploaded successfully');
        dispatch(getClientDetails());
        stopGeneralLoaderOnSuccessOrFail('uploadLogoActionLoader');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('uploadLogoActionLoader');
      displayErrors(e);
    }
  };
};

export const deleteProfileLogo = () => {
  return async dispatch => {
    startGeneralLoaderOnRequest('uploadLogoActionLoader');

    try {
      const response = await CompanyProfileApiService.deleteProfileLogo();
      if (response?.data?.status === 'SUCCESS') {
        successNotification('Profile Logo deleted successfully');
        dispatch(getClientDetails());
        stopGeneralLoaderOnSuccessOrFail('uploadLogoActionLoader');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('uploadLogoActionLoader');
      displayErrors(e);
    }
  };
};

export const saveCompanyProfilePolicyColumnList = ({ companyProfilePolicyColumnList = {}, isReset = false }) => {
  return async dispatch => {
    try {
      startGeneralLoaderOnRequest(`CompanyProfilePolicyColumn${isReset ? 'Reset' : 'Save'}ButtonLoaderAction`);
      let data = {
        columns: [],
        isReset: true,
      };
      if (!isReset) {
        const defaultFields = companyProfilePolicyColumnList.defaultFields
          .filter(field => field.isChecked)
          .map(field => field.name);
        const customFields = companyProfilePolicyColumnList.customFields
          .filter(field => field.isChecked)
          .map(field => field.name);
        data = {
          columns: [...defaultFields, ...customFields],
          isReset: false,
        };
        if (data.columns.length < 1) {
          errorNotification('Please select at least one column to continue.');
          stopGeneralLoaderOnSuccessOrFail(`CompanyProfilePolicyColumn${isReset ? 'Reset' : 'Save'}ButtonLoaderAction`);
          throw Error();
        }
      }
      const response = await CompanyProfileApiService.updateClientPolicyColumnList(data);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: CLIENT_REDUX_CONSTANTS.CLIENT_POLICY_DEFAULT_COLUMN_LIST,
          data: companyProfilePolicyColumnList,
        });
        successNotification('Columns updated successfully.');
        stopGeneralLoaderOnSuccessOrFail(`CompanyProfilePolicyColumn${isReset ? 'Reset' : 'Save'}ButtonLoaderAction`);
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail(`CompanyProfilePolicyColumn${isReset ? 'Reset' : 'Save'}ButtonLoaderAction`);
      displayErrors(e);
    }
  };
};

export const resetClientData = () => {
  return async dispatch => {
    dispatch({
      type: CLIENT_REDUX_CONSTANTS.RESET_CLIENT_DATA,
    });
  };
};
