import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../common/Button/Button';

const PublicApplicationBusinessDetailsStep = () => {
  const { clientDetail } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated ?? {});

  const termsAndConditions = clientDetail?.termsAndConditions?.termsAndConditionsUrl;

  return (
    <div className="application-information-container">
      <div className="information-step-title">Introduction</div>
      <div>{`Thank you for applying for a Credit account with ${clientDetail?.name}`}</div>
      <div>
        The online application service has been provided so we can process your application as quickly as possible.
      </div>
      <div>
        To complete your application, we will ask for the following information to be provided:
        <ul>
          <li>Your ABN and/or ACN</li>
          <li>The Director’s/Public Officer’s/Owner’s information, which may include their DOB and private address</li>
          <li>The business’ accounts payable contact details including their email address</li>
        </ul>
      </div>
      <div>
        <Button title="View TCR's Privacy Policy" className="small-button" buttonType="primary-1" />
      </div>
      <div>
        <Button title="" className="small-button" buttonType="primary-1">
          <a href={termsAndConditions} target="_blank" rel="noreferrer" className="text-decoration-none">
            {`View ${clientDetail?.name}'s Privacy Policy`}
          </a>
        </Button>
      </div>
    </div>
  );
};

export default PublicApplicationBusinessDetailsStep;
