/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Input from '../../../../common/Input/Input';
import {
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';

const PublicApplicationDirectorPrincipalDetailsStep = () => {
  const dispatch = useDispatch();
  const directorPrincipalDetails = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.directorPrincipalDetails ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const {
    australianStates = [],
    newZealandStates = [],
    entityType,
    countryList,
  } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {});

  const isAusOrNewzOne = useMemo(
    () =>
      directorPrincipalDetails.country_one?.label === 'Australia' ||
      directorPrincipalDetails.country_one?.label === 'New Zealand',
    [directorPrincipalDetails],
  );
  const isAusOrNewzSecond = useMemo(
    () =>
      directorPrincipalDetails.country_second?.label === 'Australia' ||
      directorPrincipalDetails.country_second?.label === 'New Zealand',
    [directorPrincipalDetails],
  );

  const INPUTS = useMemo(
    () => [
      {
        type: 'section',
        mainTitle: 'Director/Principal Details 1',
      },
      {
        label: 'Title',
        placeholder: 'Enter your title',
        type: 'text',
        name: 'title_one',
      },
      {
        label: 'Name*',
        placeholder: 'e.g. John Smith',
        type: 'text',
        name: 'name_one',
      },
      {
        label: 'Position*',
        placeholder: 'Enter your position',
        type: 'text',
        name: 'position_one',
      },
      {
        label: 'Date of Birth*',
        placeholder: 'DD/MM/YYYY',
        type: 'date',
        name: 'dateOfBirth_one',
      },
      {
        label: 'Driver License*',
        placeholder: '(include state)',
        type: 'text',
        name: 'driverLicense_one',
      },
      {
        label: 'Email Address*',
        placeholder: 'your@email.com',
        type: 'text',
        name: 'email_one',
      },
      {
        label: 'Mobile*',
        placeholder: '1300 048 626',
        type: 'text',
        name: 'mobile_one',
      },

      {
        label: 'Address Line 1*',
        placeholder: 'Enter your address line 1',
        type: 'text',
        name: 'addressLine1_one',
      },
      {
        label: 'Address Line 2',
        placeholder: 'Enter your address line 2',
        type: 'text',
        name: 'addressLine2_one',
      },
      {
        label: 'City',
        placeholder: 'Enter your city',
        type: 'text',
        name: 'city_one',
      },
      {
        label: 'Postcode',
        placeholder: 'Enter your postcode',
        type: 'text',
        name: 'postcode_one',
      },
      {
        label: 'Country',
        placeholder: 'Enter your country',
        type: 'select',
        name: 'country_one',
        data: countryList,
      },
      {
        label: 'State',
        placeholder: isAusOrNewzOne ? 'Select' : 'Enter your state',
        type: isAusOrNewzOne ? 'select' : 'text',
        name: 'state_one',
        data: directorPrincipalDetails.country_one?.label === 'Australia' ? australianStates : newZealandStates,
      },
      {
        type: 'section',
        mainTitle: 'Director/Principal Details 2',
      },
      {
        label: 'Title',
        placeholder: 'Enter your title',
        type: 'text',
        name: 'title_second',
      },
      {
        label: 'Name',
        placeholder: 'e.g. John Smith',
        type: 'text',
        name: 'name_second',
      },
      {
        label: 'Position',
        placeholder: 'Enter your position',
        type: 'text',
        name: 'position_second',
      },
      {
        label: 'Date of Birth',
        placeholder: 'DD/MM/YYYY',
        type: 'date',
        name: 'dateOfBirth_second',
      },
      {
        label: 'Driver License',
        placeholder: '(include state)',
        type: 'text',
        name: 'driverLicense_second',
      },
      {
        label: 'Email Address',
        placeholder: 'your@email.com',
        type: 'text',
        name: 'email_second',
      },
      {
        label: 'Mobile',
        placeholder: '1300 048 626',
        type: 'text',
        name: 'mobile_second',
      },

      {
        label: 'Address Line 1',
        placeholder: 'Enter your address line 1',
        type: 'text',
        name: 'addressLine1_second',
      },
      {
        label: 'Address Line 2',
        placeholder: 'Enter your address line 2',
        type: 'text',
        name: 'addressLine2_second',
      },
      {
        label: 'City',
        placeholder: 'Enter your city',
        type: 'text',
        name: 'city_second',
      },
      {
        label: 'Postcode',
        placeholder: 'Enter your postcode',
        type: 'text',
        name: 'postcode_second',
      },

      {
        label: 'Country*',
        placeholder: 'Select',
        type: 'select',
        name: 'country_second',
        data: countryList,
      },
      {
        label: 'State',
        placeholder: isAusOrNewzSecond ? 'Select' : 'Enter your state',
        type: isAusOrNewzSecond ? 'select' : 'text',
        name: 'state_second',
        data: directorPrincipalDetails.country_second?.label === 'Australia' ? australianStates : newZealandStates,
      },
    ],
    [
      entityType,
      directorPrincipalDetails?.notFoundAddress,
      _id,
      directorPrincipalDetails?.country_one,
      directorPrincipalDetails?.country_second,
    ],
  );

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('directorPrincipalDetails', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    (data, input) => {
      if (data?.name === 'country') {
        if (input === 'country_one') {
          updateSingleApplicationState('state_one', null);
        }
        if (input === 'country_second') {
          updateSingleApplicationState('state_second', null);
        }
      }
      updateSingleApplicationState(input, data);
    },
    [updateSingleApplicationState],
  );

  const onChangeDate = useCallback(
    (name, date) => {
      updateSingleApplicationState(name, date);
    },
    [updateSingleApplicationState],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={directorPrincipalDetails[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={() => {}}
              placeholder={input.placeholder}
              value={directorPrincipalDetails?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={directorPrincipalDetails?.[input?.name] ?? []}
              onChange={data => handleSelectInputChange(data, input?.name)}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'date':
          component = (
            <div className="date-picker-container">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={input.placeholder}
                selected={directorPrincipalDetails?.[input?.name] && new Date(directorPrincipalDetails?.[input?.name])}
                onChange={date => onChangeDate(input?.name, date)}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                popperProps={{ positionFixed: true }}
              />
              <span className="material-icons-round">event</span>
            </div>
          );
          break;
        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.name}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          <div>
            {component}
            {directorPrincipalDetails?.errors?.[input?.name] && (
              <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>
                {directorPrincipalDetails?.errors?.[input?.name]}
              </div>
            )}
          </div>
        </React.Fragment>
      );
    },
    [directorPrincipalDetails, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }

    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationDirectorPrincipalDetailsStep;
