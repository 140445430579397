import { saveApplicationStepDataToBackend, updateEditApplicationData } from '../../../redux/PublicApplicationAction';

export const publicApplicationAdditionalDocumentsStepValidation = async (dispatch, data, applicationId, clientId) => {
  const errors = {};
  let validated = true;

  const { directorIdentificationNumber, uploadDocumentApplicationData } = data ?? {};

  const requiredDocIndex = uploadDocumentApplicationData?.findIndex(
    e => e?.documentTypeId === 'Driving License or Passport',
  );

  // For address line one
  if (!directorIdentificationNumber) {
    validated = true;
  } else if (!/^(036)[A-Z0-9]{12}$/i.test(directorIdentificationNumber.trim())) {
    validated = false;
    errors.directorIdentificationNumber = "Must be 15 digits, start with '036', and be alphanumeric.";
  }
  // For driversLicenceOrPassport
  if (requiredDocIndex < 0) {
    validated = false;
    errors['Driving License or Passport'] = 'Please upload Drivers Licence or Passport';
  }

  if (validated) {
    try {
      const finalData = {
        stepper: 'additional-documents',
        applicationId,
        directorIdentificationNumber,
      };
      await dispatch(saveApplicationStepDataToBackend(finalData, clientId));
      //   Dispatch Action here to save and draft
      validated = true;
    } catch (e) {
      validated = false;
      throw Error();
    }
  }

  //   dispatch action to display errors here
  dispatch(updateEditApplicationData('documents', { errors }));
  return validated;
};
