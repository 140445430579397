import {
  publicApplicationDownloadAction,
  resetEditApplicationFieldValue,
  saveApplicationStepDataToBackend,
} from '../../../redux/PublicApplicationAction';
import { downloadAll } from '../../../../../helpers/DownloadHelper';

const downloadApplication = async (id, clientId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await publicApplicationDownloadAction(id, { clientId });
    if (response) downloadAll(response);
  } catch (e) {
    throw e;
  }
};
export const applicationConfirmationStepValidations = async (
  dispatch,
  editApplicationData,
  history,
  clientId,
  code,
) => {
  let validated = true;
  if (validated) {
    const finalData = {
      stepper: 'confirmation',
      applicationId: editApplicationData._id,
    };
    try {
      await dispatch(saveApplicationStepDataToBackend(finalData, clientId));
      await downloadApplication(editApplicationData._id, clientId);
      dispatch(resetEditApplicationFieldValue);
      history.replace(`${history.location.pathname}?code=${code}`);
    } catch (e) {
      throw Error();
    }
    validated = true;
  }
  return validated;
};
