import ApiService from '../../../services/api-service/ApiService';
import { COMPANY_PROFILE_URL, PUBLIC_APPLICATION_ANONYMOUS_URLS } from '../../../constants/UrlConstants';

const CompanyProfileApiService = {
  getClientData: () => ApiService.getData(COMPANY_PROFILE_URL.COMPANY_PROFILE_URL),
  getAnonymousClientData: clientId =>
    ApiService.getData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.CLIENT_DETAILS_URL}?clientId=${clientId}`),
  getClientPolicyList: params => ApiService.getData(COMPANY_PROFILE_URL.COMPANY_PROFILE_POLICY_LIST, { params }),
  getClientPolicyColumnList: () => ApiService.getData(COMPANY_PROFILE_URL.COMPANY_PROFILE_POLICY_COLUMN_LIST),
  updateClientPolicyColumnList: data =>
    ApiService.putData(COMPANY_PROFILE_URL.COMPANY_PROFILE_POLICY_COLUMN_LIST, data),
  uploadTermsAndConditions: data => ApiService.postData(COMPANY_PROFILE_URL.COMPANY_PROFILE_DOCUMENT_UPLOAD, data),
  uploadProfileLogo: data => ApiService.postData(COMPANY_PROFILE_URL.COMPANY_PROFILE_LOGO_UPLOAD, data),
  deleteProfileLogo: () => ApiService.deleteData(COMPANY_PROFILE_URL.COMPANY_PROFILE_LOGO_DELETE),
  getDocumentType: () => ApiService.getData(COMPANY_PROFILE_URL.DOCUMENTS.GET_DOCUMENT_TYPE_LIST_URL),
  deleteTermsAndConditionsDocument: documentId =>
    ApiService.deleteData(`${COMPANY_PROFILE_URL.DOCUMENTS.DELETE_DOCUMENT_URL}${documentId}`),
};

export default CompanyProfileApiService;
