/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';
import Checkbox from '../../../../common/Checkbox/Checkbox';
import RadioButton from '../../../../common/RadioButton/RadioButton';

const PublicApplicationConfirmationStep = () => {
  const { businessDetails, contactDetails, businessAddressesDetails, directorPrincipalDetails, partners, documents } =
    useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication ?? {});

  const confirmationDetails = [
    {
      title: 'Business Details',
      type: 'main-title',
    },
    {
      title: 'Business',
      type: 'title',
    },
    {
      title: 'Sales Rep Name',
      value: businessDetails?.salesRepName,
      label: 'salesRepName',
      type: 'text',
    },
    {
      title: 'Business Numbers',
      value: `ABN ${businessDetails?.abn}`,
      label: 'abn',
      type: 'text',
    },
    {
      title: 'ACN',
      value: businessDetails?.acn,
      label: 'acn',
      type: 'text',
    },
    {
      title: 'Business structure',
      value: businessDetails?.businessStructure?.label,
      label: 'businessStructure',
      type: 'text',
    },
    {
      title: 'Buisness Start Date',
      value: businessDetails?.buisnessStartDate ? moment(businessDetails?.buisnessStartDate).format('YYYY-MM-DD') : '',
      label: 'buisnessStartDate',
      type: 'text',
    },

    {
      title: 'Are You a New Applicant or an Existing Customer?',
      value: businessDetails?.newOrExistingApplicant?.label,
      label: 'newOrExistingApplicant',
      type: 'text',
    },
    {
      title: 'How Did You Hear About us?',
      value: businessDetails?.reference?.label,
      label: 'reference',
      type: 'text',
    },

    {
      title: 'Names',
      type: 'title',
    },
    {
      title: 'Legal Entity Name',
      value: businessDetails?.legalEntityName,
      label: 'legalEntityName',
      type: 'text',
    },
    {
      title: 'Main trading name',
      value: businessDetails?.mainTradingName,
      label: 'mainTradingName',
      type: 'text',
    },
    {
      title: 'Other trading names',
      value: businessDetails?.otherTradingNames,
      label: 'otherTradingNames',
      type: 'text',
    },

    {
      title: 'Account Details',
      type: 'title',
    },
    {
      title: 'Estimated Monthly Purchases',
      value: businessDetails?.estimatedMonthlyPurchases,
      label: 'estimatedMonthlyPurchases',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Contact Details',
      type: 'main-title',
    },
    {
      title: 'Primary Contact',
      type: 'title',
    },
    {
      title: 'Full name',
      value: contactDetails?.primaryFullName,
      label: 'primaryFullName',
      type: 'text',
    },
    {
      title: 'Position',
      value: contactDetails?.primaryPosition,
      label: 'primaryPosition',
      type: 'text',
    },
    {
      title: 'Email',
      value: contactDetails?.primaryEmail,
      label: 'primaryEmail',
      type: 'text',
    },
    {
      title: 'Phone',
      value: contactDetails?.primaryPhone,
      label: 'primaryPhone',
      type: 'text',
    },

    {
      title: 'Accounts Payable Contact',
      type: 'title',
    },
    {
      title: 'Full name',
      value: contactDetails?.payableFullName,
      label: 'payableFullName',
      type: 'text',
    },

    {
      title: 'Email',
      value: contactDetails?.payableEmail,
      label: 'payableEmail',
      type: 'text',
    },
    {
      title: 'Phone',
      value: contactDetails?.payablePhone,
      label: 'payablePhone',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Additional Documents',
      type: 'main-title',
    },
    {
      title: 'Documents',
      type: 'title',
    },
    {
      title: 'Director Identification Number',
      value: documents?.directorIdentificationNumber,
      label: 'directorIdentificationNumber',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Director/Principal Details',
      type: 'main-title',
    },
    {
      title: 'Director/Principal Details 1',
      type: 'title',
    },
    {
      title: 'Name',
      value: directorPrincipalDetails?.title_one,
      label: 'title_one',
      type: 'text',
    },
    {
      title: 'Position',
      value: directorPrincipalDetails?.position_one,
      label: 'position_one',
      type: 'text',
    },
    {
      title: 'Mobile',
      value: directorPrincipalDetails?.mobile_one,
      label: 'mobile_one',
      type: 'text',
    },
    {
      title: 'Email',
      value: directorPrincipalDetails?.email_one,
      label: 'email_one',
      type: 'text',
    },
    {
      title: 'Address 1',
      value: directorPrincipalDetails?.addressLine1_one,
      label: 'addressLine1_one',
      type: 'text',
    },
    {
      title: 'Address 2',
      value: directorPrincipalDetails?.addressLine2_one,
      label: 'addressLine2_one',
      type: 'text',
    },
    {
      title: 'City',
      value: directorPrincipalDetails?.city_one,
      label: 'city_one',
      type: 'text',
    },
    {
      title: 'Postcode',
      value: directorPrincipalDetails?.postcode_one,
      label: 'postcode_one',
      type: 'text',
    },
    {
      title: 'Country',
      value: directorPrincipalDetails?.country_one?.label,
      label: 'country_one',
      type: 'text',
    },
    {
      title: 'State',
      value: directorPrincipalDetails?.state_one?.label,
      label: 'state_one',
      type: 'text',
    },
    {
      title: 'Director/Principal Details 2',
      type: 'title',
    },
    {
      title: 'Name',
      value: directorPrincipalDetails?.title_second,
      label: 'title_second',
      type: 'text',
    },
    {
      title: 'Position',
      value: directorPrincipalDetails?.position_second,
      label: 'position_second',
      type: 'text',
    },
    {
      title: 'Mobile',
      value: directorPrincipalDetails?.mobile_second,
      label: 'mobile_second',
      type: 'text',
    },
    {
      title: 'Email',
      value: directorPrincipalDetails?.email_second,
      label: 'email_second',
      type: 'text',
    },
    {
      title: 'Address 1',
      value: directorPrincipalDetails?.addressLine1_second,
      label: 'addressLine1_second',
      type: 'text',
    },
    {
      title: 'Address 2',
      value: directorPrincipalDetails?.addressLine2_second,
      label: 'addressLine2_second',
      type: 'text',
    },
    {
      title: 'City',
      value: directorPrincipalDetails?.city_second,
      label: 'city_second',
      type: 'text',
    },
    {
      title: 'Postcode',
      value: directorPrincipalDetails?.postcode_second,
      label: 'postcode_second',
      type: 'text',
    },
    {
      title: 'Country',
      value: directorPrincipalDetails?.country_second?.label,
      label: 'country_second',
      type: 'text',
    },
    {
      title: 'State',
      value: directorPrincipalDetails?.state_second?.label,
      label: 'state_second',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Registered Address',
      type: 'main-title',
    },
    {
      title: 'Address 1',
      value: businessAddressesDetails?.addressLine1,
      type: 'text',
    },
    {
      title: 'Address 2',
      value: businessAddressesDetails?.addressLine2,
      type: 'text',
    },
    {
      title: 'City',
      value: businessAddressesDetails?.city,
      type: 'text',
    },
    {
      title: 'Postcode',
      value: businessAddressesDetails?.postcode,
      type: 'text',
    },
    {
      title: 'Country',
      value: businessAddressesDetails?.country?.label,
      type: 'text',
    },
    {
      title: 'State',
      value: businessAddressesDetails?.state?.label || businessAddressesDetails?.state,
      type: 'text',
    },
  ];

  const getConfirmationComponentFromType = useCallback(
    detail => {
      switch (detail.type) {
        case 'text':
          return (
            <>
              <span className="application-confirmation-title">{detail?.title}</span>
              <span className="detail-value">{detail?.value ?? '-'}</span>
            </>
          );
        case 'select':
          return (
            <>
              <span className="application-confirmation-title">{detail?.title}</span>
              <span className="detail-value">{detail?.value?.label ?? '-'}</span>
            </>
          );
        case 'ifYesText':
          return (
            <>
              <span className="application-confirmation-title">{detail?.title}</span>
              <span className="long-text">{detail?.value ?? '-'}</span>
            </>
          );
        case 'title':
          return (
            <>
              <span className="title">{detail?.title}</span>
            </>
          );
        case 'main-title-director-details':
          return (
            <>
              <span className="main-title-director-details">{detail?.title}</span>
            </>
          );
        case 'radio':
          return (
            <>
              <span className="radio-title">{detail?.title}</span>
              <span className="radio-buttons">
                <RadioButton
                  disabled
                  id={`${detail?.id}-yes`}
                  name={detail?.name}
                  label="Yes"
                  value
                  checked={detail?.value}
                />
                <RadioButton
                  disabled
                  id={`${detail?.id}-no`}
                  name={detail?.name}
                  label="No"
                  value={false}
                  checked={!detail?.value}
                />
              </span>
            </>
          );
        case 'checkbox':
          return (
            <>
              <Checkbox className="grid-checkbox" title={detail?.title} disabled={detail?.isDisabled} />
            </>
          );
        case 'main-title':
          return (
            <>
              <div className="main-title">{detail?.title}</div>
            </>
          );
        case 'line':
          return <div className="horizontal-line" />;
        case 'blank':
          return (
            <>
              <div />
              <div />
            </>
          );
        case 'array':
          return detail?.data?.map(elem => elem.map(getConfirmationComponentFromType));
        default:
          return null;
      }
    },
    [partners],
  );
  return (
    <div className="application-confirmation-step">{confirmationDetails?.map(getConfirmationComponentFromType)}</div>
  );
};

export default PublicApplicationConfirmationStep;
