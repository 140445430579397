import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PublicApplicationBusinessDetailsStep from './component/PublicApplicationBusinessDetailsStep/PublicApplicationBusinessDetailsStep';
import {
  addPersonDetail,
  changeEditApplicationFieldValue,
  getApplicationDetail,
  resetEditApplicationFieldValue,
} from './redux/PublicApplicationAction';
import { useQueryParams } from '../../hooks/GetQueryParamHook';
import Loader from '../../common/Loader/Loader';
import PAStepper from './component/components/PublicApplicationStepper';

import PublicApplicationInformationStep from './component/PublicApplicationInformationStep/PublicApplicationInformationStep';
import PublicApplicationContactDetailsStep from './component/PublicApplicationContactDetailsStep/PublicApplicationContactDetailsStep';
import PublicApplicationDirectorPrincipalDetailsStep from './component/PublicApplicationDirectorPrincipalDetailsStep/PublicApplicationDirectorPrincipalDetailsStep';
import PublicApplicationBusinessAddressesDetailsStep from './component/PublicApplicationBusinessAddressesDetailsStep/PublicApplicationBusinessAddressesDetailsStep';
import PublicApplicationConfirmationStep from './component/PublicApplicationConfirmationStep/PublicApplicationConfirmationStep';
import PublicApplicationAdditionalDocumentsStep from './component/PublicApplicationAdditionalDocumentsStep/PublicApplicationAdditionalDocumentsStep';
import { publicApplicationBusinessDetailsStepValidation } from './component/PublicApplicationBusinessDetailsStep/validations/publicApplicationBusinessDetailsStepValidation';
import { publicApplicationContactDetailsStepValidation } from './component/PublicApplicationContactDetailsStep/validations/publicApplicationContactDetailsStepValidation';
import { publicApplicationAdditionalDocumentsStepValidation } from './component/PublicApplicationAdditionalDocumentsStep/validations/publicApplicationAdditionalDocumentsStepValidation';
import { publicApplicationDirectorPrincipalDetailsStepValidation } from './component/PublicApplicationDirectorPrincipalDetailsStep/validations/publicApplicationDirectorPrincipalDetailsStepValidation';
import { publicApplicationBusinessAddressesDetailsStepValidation } from './component/PublicApplicationBusinessAddressesDetailsStep/validations/publicApplicationBusinessAddressesDetailsStepValidation';
import { applicationConfirmationStepValidations } from './component/PublicApplicationConfirmationStep/validations/publicApplicationConfirmationStepValidation';

const STEP_COMPONENT = [
  <PublicApplicationInformationStep />,
  <PublicApplicationBusinessDetailsStep />,
  <PublicApplicationContactDetailsStep />,
  <PublicApplicationAdditionalDocumentsStep />,
  <PublicApplicationDirectorPrincipalDetailsStep />,
  <PublicApplicationBusinessAddressesDetailsStep />,
  <PublicApplicationConfirmationStep />,
];

const steps = [
  {
    icon: 'checklist_rtl',
    text: 'Introduction',
    name: 'introduction',
    hidden: true,
  },
  {
    icon: 'local_police',
    text: 'Business Details',
    name: 'businessDetails',
  },
  {
    icon: 'contact_page',
    text: 'Contact Details',
    name: 'contactDetails',
  },
  {
    icon: 'description',
    text: 'Additional Documents',
    name: 'documents',
  },
  {
    icon: 'groups',
    text: 'Director/Principal Details',
    name: 'directorPrincipalDetails',
  },
  {
    icon: 'add_location_alt',
    text: 'Business Addresses',
    name: 'businessAddressesDetails',
  },
  {
    icon: 'list_alt',
    text: 'Confirm',
    name: 'confirm',
  },
];

const GeneratePublicApplication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { code, applicationId } = useQueryParams();
  const { applicationStage, ...editApplicationData } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication ?? {},
  );

  const { _id: clientId } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {},
  );

  const { generateApplicationPageLoaderAction } = useSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? false,
  );
  //
  // // for stepper components
  // const FILTERED_STEP_COMPONENT = useMemo(() => {
  //   const finalSteps = [...STEP_COMPONENT];
  //   if (!['PARTNERSHIP', 'TRUST', 'SOLE_TRADER'].includes(editApplicationData?.company?.entityType?.value ?? '')) {
  //     finalSteps.splice(1, 1);
  //   }
  //
  //   return finalSteps;
  // }, [editApplicationData?.company?.entityType, STEP_COMPONENT]);

  const onChangeIndex = useCallback(newIndex => {
    dispatch(changeEditApplicationFieldValue('applicationStage', newIndex));
  }, []);

  useEffect(async () => {
    return () => dispatch(resetEditApplicationFieldValue);
  }, []);

  useEffect(() => {
    if (applicationId && clientId) {
      dispatch(getApplicationDetail(applicationId, clientId, code, history));
    }
  }, [applicationId, code]);

  useEffect(() => {
    if (editApplicationData && editApplicationData?._id) {
      const params = {
        applicationId: editApplicationData?._id,
        code,
      };
      const url = Object.entries(params)
        .filter(arr => arr[1] !== undefined)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');

      history.replace(`${history.location.pathname}?${url}`);
    }
  }, [editApplicationData?._id, history]);

  const backToApplication = useCallback(() => {
    history.replace('/applications');
  }, [history]);

  const addStepClick = useCallback(() => {
    dispatch(addPersonDetail('individual'));
  }, []);

  const onNextClick = useCallback(async () => {
    const data = editApplicationData?.[steps?.[applicationStage ?? 0]?.name];
    try {
      switch (steps?.[applicationStage ?? 0]?.name) {
        case 'introduction':
          return true;

        case 'businessDetails':
          return await publicApplicationBusinessDetailsStepValidation(dispatch, data, applicationId, clientId);

        case 'contactDetails':
          return await publicApplicationContactDetailsStepValidation(dispatch, data, applicationId, clientId);

        case 'documents':
          return await publicApplicationAdditionalDocumentsStepValidation(dispatch, data, applicationId, clientId);

        case 'directorPrincipalDetails':
          return await publicApplicationDirectorPrincipalDetailsStepValidation(dispatch, data, applicationId, clientId);

        case 'businessAddressesDetails':
          return await publicApplicationBusinessAddressesDetailsStepValidation(dispatch, data, applicationId, clientId);

        case 'confirm':
          return await applicationConfirmationStepValidations(dispatch, editApplicationData, history, clientId, code);

        default:
          return false;
      }
    } catch (e) {
      /**/
    }
    return false;
  }, [editApplicationData, applicationStage, applicationId, clientId, code]);

  return (
    <>
      {!generateApplicationPageLoaderAction ? (
        <>
          <div className="breadcrumb-button-row">
            <div className="breadcrumb">
              <span onClick={backToApplication}>Application List</span>
              <span className="material-icons-round">navigate_next</span>
              <span>Generate Application</span>
            </div>
          </div>
          <PAStepper
            className="mt-10"
            steps={steps}
            stepIndex={applicationStage ?? 0}
            onChangeIndex={onChangeIndex}
            nextClick={onNextClick}
            addStepClick={addStepClick}
          >
            {STEP_COMPONENT[applicationStage ?? 0]}
          </PAStepper>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default GeneratePublicApplication;
